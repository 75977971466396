import * as React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '@components/Layout'
import UI, { Box } from '@components/UI'
import { Query } from '@generated/graphql'

const H3 = styled(UI.H3).attrs({
  pb: 3
})``

const Text = styled(UI.Text).attrs({
  pb: 4,
  mb: 3
})``

const About = ({ data }: { data: Query }) => {
  const { santiandtugce } = data.allMarkdownRemark.nodes[0].frontmatter

  return (
    <Layout title="About">
      <H3>Santi & Tuğçe</H3>
      <Text>{santiandtugce}</Text>
    </Layout>
  )
}

export default About

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.about.md/" } }
    ) {
      nodes {
        frontmatter {
          santiandtugce
        }
      }
    }
  }
`
